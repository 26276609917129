import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

export interface Tab {
  label: string;
  value: string;
  quantity?: number;
  icon?: string;
}

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {
  @Input() translatePrefix = '';
  @Input() type: 'underline' | 'pill' = 'underline';
  @Input({ required: true }) tabActive: Tab;
  @Input({ required: true }) tabs: Tab[] = [];
  @Output() onTabChange = new EventEmitter<Tab>();
  @Output() tabActiveChange = new EventEmitter<Tab>();

  onClickTab(tab: Tab) {
    this.tabActive = tab;
    this.tabActiveChange.emit(this.tabActive);
    this.onTabChange.emit(tab);
  }
}
