<div class="flex" [ngClass]="type == 'underline' ? 'h-13' : 'h-9 bg-neutral-200 p-1 w-fit rounded-sm'">
  @for (tab of tabs; track tab.value) {
    @if (type == 'underline') {
      <div
        class="h-full flex items-center justify-center cursor-pointer px-5 border-b-2 gap-2 font-medium"
        [ngClass]="tab.value == tabActive.value ? 'text-blue-600  border-blue-600' : 'border-transparent'"
        (click)="onClickTab(tab)">
        {{ translatePrefix ? (translatePrefix + tab.label | translate) : tab.label }}

        @if (tab.quantity !== undefined) {
          <div
            class="w-5 h-5 rounded-sm flex items-center justify-center font-medium text-2"
            [ngClass]="tab.value == tabActive.value ? 'text-white bg-blue-600' : 'text-neutral-800 bg-neutral-300'">
            {{ tab.quantity }}
          </div>
        }
      </div>
    } @else {
      <div
        class="h-full flex items-center justify-center cursor-pointer px-3 rounded"
        [ngClass]="tab.value == tabActive.value ? 'bg-white shadow text-blue-600' : ''"
        (click)="onClickTab(tab)">
        {{ translatePrefix ? (translatePrefix + tab.label | translate) : tab.label }}
      </div>
    }
  }
</div>
